import Error from "next/error";
import Head from "next/head";

const ErrorPage = () => (
  <>
    <Head>
      <meta name="robots" content="noindex" />
    </Head>
    <Error statusCode={404} withDarkMode={false} />
  </>
);

export default ErrorPage;
